/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

// @ts-expect-error Missing types: https://github.com/ChetSocio/easy-magnify/issues/1
import ImageMagnify from 'easy-magnify';
import { type ReactImageMagnifyProps } from './types';

export const ReactImageMagnify = (props: ReactImageMagnifyProps) => {
  return <ImageMagnify {...props} />;
};
