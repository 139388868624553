/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { CarouselItem } from '@/components/Carousel/ThumbnailComponent/types';
import { trackImageInteractionPDP } from '../../../utils/analytics/trackImageInteractionPDP';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import React, { KeyboardEventHandler, useEffect, useRef } from 'react';
import { ReactImageMagnify } from '../ReactImageMagnify';
import styles from '../styles.module.scss';
import { useFeatureFlag } from '@/features/globalConfig';
import { defaultVideoPoster } from '@/constants/images/defaultVideoPoster';
import dynamic from 'next/dynamic';

const LazyThreeSixtyViewer = dynamic(() => import('../../ThreeSixtyViewer'), {
  ssr: false,
});
import { mapAprimoUrlToBeRemoved } from '../utils/mapAprimoUrlToBeRemoved';

type Props = {
  caption?: string;
  enlargedMedia?: CarouselItem;
  handleOnKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onClick?: () => void;
  setAprimoVideoTag?: React.Dispatch<React.SetStateAction<HTMLVideoElement | null>>;
};

export function EnlargedMediaComponent(props: Props) {
  const { handleOnKeyDown, onClick, caption, enlargedMedia, setAprimoVideoTag } = props;
  const {
    alt = '',
    type,
    enlargedMediaUrl,
    mediumImageURL,
    title,
    threeSixtyImagesUrl = '',
  } = enlargedMedia || {};
  const isVideo = type === 'video';
  const is360 = type === 'spin360';
  const enlargedImgBoxRef = useRef<HTMLDivElement>(null);
  const aprimoPDPVideosEnabled = useFeatureFlag('APRIMO_PDP_VIDEO_ENABLED') === 'true';

  useEffect(() => {
    if (aprimoPDPVideosEnabled && isVideo && setAprimoVideoTag) {
      const video = document.getElementById('aprimo-video') as HTMLVideoElement;
      setAprimoVideoTag(video);
    }
  }, [aprimoPDPVideosEnabled, isVideo, setAprimoVideoTag]);

  const handleOnClick = () => {
    trackImageInteractionPDP('Enlarge Image-PDP');
    onClick?.();
  };

  const updateOutline = (outlineValue: string) => {
    if (enlargedImgBoxRef.current) {
      enlargedImgBoxRef.current.style.outline = outlineValue;
    }
  };

  return (
    <div className={styles.enlargedMediaContainer}>
      {isVideo ? (
        <div className={cx(styles.videoContainer, styles.videoContainerInline)}>
          {aprimoPDPVideosEnabled ? (
            <video
              controls
              id="aprimo-video"
              title={title}
              className={styles.enlargedIframe}
              // to be set back to enlargedMediaUrl only with 6.7.0s
              src={mapAprimoUrlToBeRemoved(enlargedMediaUrl)}
              poster={defaultVideoPoster.src}
              preload="metadata"
            />
          ) : (
            <iframe
              title={title}
              frameBorder="0"
              allowFullScreen={true}
              // @ts-expect-error fix type error
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              className={styles.enlargedIframe}
              src={enlargedMediaUrl}
              loading="lazy"
            /> // TODO: Fix this the next time the file is edited.
          )}
        </div>
      ) : is360 ? (
        <div className={styles.enlargedImgBox} data-testid="enlarged-image-360-box">
          <LazyThreeSixtyViewer url={threeSixtyImagesUrl} alt={alt} />
        </div>
      ) : (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={styles.enlargedImgBox}
          data-testid="enlarged-image-box" // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          onKeyDown={handleOnKeyDown}
          onKeyUp={() => updateOutline('')}
          onMouseDown={() => updateOutline('none')}
          onClick={handleOnClick}
          ref={enlargedImgBoxRef}
        >
          <ReactImageMagnify
            smallImage={{
              alt,
              isFluidWidth: true,
              src: mediumImageURL ?? enlargedMediaUrl ?? '',
            }}
            largeImage={{
              src: enlargedMediaUrl ?? '',
              width: 1000,
              height: 1000,
            }}
            enlargedImageContainerDimensions={{
              width: 541,
              height: 541,
            }}
            enlargedImagePosition="over"
          />
        </div>
      )}
      {caption && (
        <div
          className={cx(
            azCommonStyles['az-caption'],
            azCommonStyles['az-padding-top-xxxs'],
            azCommonStyles['az-padding-bottom-xxxs']
          )}
        >
          {caption}
        </div>
      )}
    </div>
  );
}
